.profile-dropdown {
    display: flex;
    flex-direction: column;
}

.your-links:hover {
    cursor: pointer;
}

.prof-button-div {
    background-color:rgb(220, 94, 28);
}

.prof-button-div > div {
    color: black;
    font-size: 1em;
    margin-bottom: 10px;
    font-weight: bolder;
}

.your-links:hover {
    color: white;
}


.demo-user-button {
    cursor: pointer;
}

.fa-user-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 120px;
    height: 50px;
    border-radius: 5px;
    border: none;
    background-color: #C3C5D2;
    /* background-color: blue; */
    
}

/* .user-nav-button {
    background-color: #C5CBD9;
} */

.fa-user-button:hover {
    /* background-color: #c6c7d6; */
    background-color: darkgrey;
}

.account-text {
    margin: 0px;
}