.gr-up-div {
    display: flex;
}

.gr-up-img {
    width: 100px;
    height: 100px;
}

.group-up-div-a {
    font-size: 5.6em;
    color: rgb(250, 156, 30);
}

.gr-img-div {
    display: flex;
    align-items: center;
}

.gr-up-div {
    display: flex;
    justify-content: center;
    margin: 0px;
}

.subtitle {
    display: block;
    font-size: 4em;
    text-align: center;
}


.user-landing-wrapper {
    margin-bottom: 20px;
}


.landing-background-img-div  {
    display: flex;
    justify-content: center;
}

.user-background-img {
    object-fit: cover;
    width: 100%;
    height: 700px;
}

.nav-div {
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.gr-background-img {
    height: 500px;
}

.user-vod {
    height: 900px;
    position: absolute;
    top: 0px;
    z-index: -1
}


.under-vod-spacing {
    display: block;
    margin-bottom: 600px;
}

.vod-ow-img {
    width: fit-content;
}

.subtitle {
    background-color: #1D263A;
    color: white;
}