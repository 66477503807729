.g-detail-img-div {
    display: flex;
    justify-content: center;
}

.g-subinfo-div, .g-name-div {
    margin-left: 15px;
}

.g-detail-img {
    object-fit: cover;
    max-height: 340px;
    max-width: 600px;
}

.upcoming-events {
    font-size: 2em;
}

.g-description {
    margin-bottom: 10px;
}

.g-name-div {
    font-size: 2em;
    color: rgb(220, 94, 28)
}

.g-subinfo-div {
    font-size: 1em;
}

.g-info-2 {
    height: fit-content;
}

.g-detail-img {
    display: flex;
    align-items: center;
}

.g-description-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}


.g-desc-div {
    justify-content: flex-start;
}

.g-subinfo-div {
    margin-left: 0px;
}

.g-desc {
    font-size: 1.5em;
}

.g-detail-img {
    display: block;
}


.g-name-pic-img {
    display: flex;
    justify-content: flex-start;
}

.g-detail-img-div {
    display: flex;
    justify-content: flex-start;
    background-color: #1D263A;
}

.g-subinfo-div {
    background-color: #1D263A;
    padding-left: 8%;
    
}

.g-detail-events-div {
    background-color: #95ABCA;
    padding-left: 8%;
}

.g-detail-members-div  {
    background-color: #166CD0;
    padding-left: 8%;
}

.g-img-icon {
    width: 130px;
    height: 130px;
    margin-left: 8%;
    border: 2px solid white;
}

.g-info-2 {
    font-style: italic;
    margin-left: 2%;
}

.g-detail-top-background {
    height: 450px;
    width: 100%;
    background-color: #1D263A;
    position: absolute;
    top: 0px;
    z-index: -1;
    color: #1D263A;
}

.g-desc-div {
    padding-bottom: 30px
}

.g-name-div,.g-subinfo-div, .g-desc-div, .members-text {
    color: white;
}

.g-detail-title-text {
    font-weight: bold;
}

.upcoming-events-index {
    padding-bottom: 8px;
}

.upcoming-events-index {
    display: flex;
    flex-wrap: wrap;
}