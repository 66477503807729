.profile-dropdown {
  position: absolute;
  right: 30px;
}

.hidden {
  display: none;
}


.nav-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;
  /* padding-left: 5%; */
  /* padding: 0px; */
}


#modal-content {
  color: white;
  max-width: 500px;
  width: 500px;
  height: fit-content;
  padding-left: 65px;
  padding-right: 65px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 25px;
}

.x-div {
  display: flex;
  align-items: flex-end;
}

.x-div:hover {
  cursor: pointer;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-modal-div-a {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#x {
  color: grey;
}

.login-form > *{
  width: 100%;
}

.email-input, .password-input {
  height: 28px;
}

.login-button {
  height: 40px;
}

.login-button:active {
  background-color: rgb(220, 94, 28);
}

.gamepad-login-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fa-gamepad {
 font-size: xx-large;
}

.login-text {
  font-size: x-large;
}

.login-gamepad {
  margin-bottom: 15px;
}

.password-icon-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.toggle-password-div:hover, .nav-gamepad:hover {
  cursor: pointer;
}

.email-input, .password-input {
  margin-bottom: 20px;
}

.email-div, .password-div {
  margin-bottom: 10px;
}

.redirect-gr-img {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.inner-nav-div {
  width: 100%;
  height: 70px;
  background-color: #C3C5D2;
  
  /* background-color: blue; */
  /* color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  /* position: sticky;
  top: 0;
  z-index: 5; */
}

.home-redirect-div {
  padding-left: 7%;
}

.account-text {
  color: black;
}