.user-card-div {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    border-color: black;
    color: white;
    border: 2px solid white;
    border-radius: 25px;
    padding: 5px 10px
}

.user-card-div:hover {
    border-color: white
}

.user-card-name {
    font-size: x-large;
}

.userIndexItem:hover {
    cursor: auto;
}

.userIndexItem {
    margin: 0px;
}