.cp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sp-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 600px;
}

.q-text {
    font-weight: 700;
}

.sub-q-text {
    font-size: 14px;
}

.product-description-div,
.product-name-div,
.product-available-div,
.product-price-div,
.product-img1-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5px;
}

.product-category-div,
.product-shipping-div {
    width: 50%;
}

.error {
    font-size: 14px;
    color: red;
}


.product-name-div,
.product-available-div,
.product-price-div,
.product-img1-div {
    align-items: center;
    flex-direction: row;
}

.create-shop-grey {
    font-size: .8rem;
    color: rgb(68, 68, 68);
    width: 20rem;
}

.form-title {
    font-family: 'Libre Baskerville', serif;
    margin-top: 5rem;
    justify-content: center;
}

.form-sub {
    font-family: 'Libre Baskerville', serif;
    justify-content: center;
}


.product-description-div {
    flex-direction: row;
}

.input-field {
    height: 1.3rem;
    border-radius: 0.3rem;
}


.product-name-div,
.product-description-div {
    align-items: center;
}

.description-text {
    width: 100%;
}


.description-input {
    height: 100px;
    min-height: min-content;
    padding-bottom: 30px;
    border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
}

.description-title-sub-text {
    position: relative;
    bottom: 9px;
}

.product-category-div {
    margin-bottom: 10px;
}

.create-product-button {
    margin-left: 16px;
}

textarea,
option,
select {
    font-family: 'Mulish', sans-serif;
}

.category-shipping-div {
    display: flex;
    flex-direction: row;
}

.check-box-text {
    max-width: fit-content;
    white-space: wrap;
}

.check-box {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
}

.submit-create-shop {
    font-size: 1rem;
    font-weight: bolder;
}

