/* TODO Add site wide styles */

body, button {
    /* background-color: black; */
    background-color: #DDE7F1;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
}



button:hover {
    cursor: pointer;
}

