.introTile {
    display: flex;
    flex-direction: column;
}

.title-intro-div {
    margin-left: none;
}

#title-intro-tile {
    margin-left: none;
    color: black;
}

#text-intro-tile {
    padding-right: 10%;
    display: flex;
    justify-content: flex-start;
}

#intro-img {
    height: 390px;
    max-height: 390px;
    width: 385px;
    min-width: 385px;
}
