.how-g-works {
    font-size: 3em;
    text-align: center;
}

.tiles-index {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

/* intro tiles layout changes from rows to columns given smaller screen sizes */
@media (max-width: 1155px) {
    .tiles-index {
        flex-direction: column;
        align-items: center;
    }
}

.footer-link {
    padding-top: 12px;
}

.intro-img-div {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: flex-start;
}

.g-detail-img {
    object-fit: cover;
}

.intro-text-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 385px;
    text-align: center;
}

.g-name-div {
    margin: 0px;
}

.meet-text {
    text-align: center;
}

.user-landing, .landing {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 20px;
}

