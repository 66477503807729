.groupIndex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.groups-h1 {
    display: flex;
    justify-content: center;
    font-size: 3em;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    margin-bottom: 30px;
}

.groupIndexItem {
    display: flex;
    justify-content: center;
    flex-basis: 50%;
}


.group-card-div {
    margin-right: 0px;
}

.img-top-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.groups-images {
    display: flex;
    justify-content: space-around;
}

.groupIndex {
    width: 700px;
}

.hero-img {
    margin-bottom: 50px;
}

.groupIndexItem {
    height: fit-content;
}