.e-hosted-div {
    font-size: 1em;
    margin-left: 0px;
}

.e-top-div {
    display: flex;
}

.img-e-info-div {
    display: flex;
    background-color: #94ABCA;
}

.e-title {
    font-size: 1.2em;
    color: white;
}

.e-subinfo-div {
    max-width: 380px;
    width: fit-content;
    height: fit-content;
    font-size: xx-large;
    background-color: #94ABCA;
}

.e-format-div {
    color: white;
    font-size: .7em;
}

.format-title {
    font-size: .7em;
}

.e-top-div {
    align-items: center;
}

.e-details-div {
    background-color: #1D263A;
    padding-left: 8%;
    font-size: 1em;
    color: white;
    padding-bottom: 30px;
}

.e-attendees-div {
    background-color: #166CD0;
    padding-left: 8%;
}

.e-detail-bottom-background {
    height: 450px;
    width: 100%;
    background-color: #166CD0;
    position: absolute;
    bottom: 0px;
    z-index: -1;
    color: #1D263A;
}

.e-group-div {
    margin-left: 8%;
}

.e-detail-events-div {
    padding-left: 8%;
    background-color: #166CD0;

}

.e-attendees-div {
    color: white;
}

.e-detail-g-div {
    color: white;
    font-size: 2.5em;
}

.up-events-text {
    color: white;
}

.up-events-text {
    margin-bottom: 0px;
}

.e-name-div {
    display: flex;
    align-items: flex-start;
}

.e-name-div {
    align-self: flex-start;
}




.e-g-name-size {
    font-size: 2em;
}

.e-g-under-size {
    font-size: 1.5em;
}

.under-g-padding {
    padding-bottom: 30px;
}

.format {
    font-size: .7em;
}

.under-details-wrapper {
    background-color: #1D263A;
    font-size: 2em;
    padding-left: 8%;
    color: white;
}

.associated-users {
    font-weight: bold;
}