.footer-container {
    margin-top: 2rem;
    background-color: rgb(220, 94, 28);
}

.footer-title {
    text-align: center;
    margin-top: 0px;
    padding-top: 16px;
    font-size: 1.5em;
    color: black;
    font-weight: bold;
    margin-bottom: 0px;
}

.about-creators {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: space-between;
    color: black;
    font-weight: bold;
}

.dev-name {
    font-weight: bold;
    margin-left: 3rem;
}

.github-icon,
.linkedin-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: .5rem;
    align-items: center;
}

.footer-person {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media only screen and (max-width: 90rem) {
    .dev-name {
        margin-left: 2rem;
    }
}

.icon {
    color: white;
}

.dev-name {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

.footer-link {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 15px;
}