.email-container, .password-container {
    display: flex;
    flex-direction: column;
}



.torb-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lets-getin-there {
    font-size: 2em;
    font-weight: bold;
}

.login-text {
    font-size: 3em;
    font-weight: bold;
}

.demo-button {
    background-color: #DDE7F1;
    color: black;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.login-button {
    margin-bottom: 10px;
    cursor: pointer;
}